import { DuckSelector, objectDuckCreator } from '@atc/modular-redux';

const consumerRatingsDuck = objectDuckCreator({
    store: 'consumerRatings',
    initialState: {},
}).extend({
    selectors: {
        getConsumerRatings: new DuckSelector((selectors) => (state) => selectors.getDuckState(state)),
    },
});

export default consumerRatingsDuck;
