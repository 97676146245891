import { DuckSelector, objectDuckCreator } from '@atc/modular-redux';

const activeInteractionDuck = objectDuckCreator({
    store: 'activeInteraction',
    initialState: {
        isOneClickOptedIn: false,
    },
}).extend({
    selectors: {
        isOneClickOptedIn: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).isOneClickOptedIn),
    },
});

export default activeInteractionDuck;
