import { authUtilityLoader } from '@consumer/auth-utility-loader';

export default async function importAuthUtilityBundle() {
    const {
        getConsumerId,
        isUserSignedIn,
        once,
        showAuthModal,
        getUserAttributes,
    } = await authUtilityLoader();

    return {
        getConsumerId,
        isUserSignedIn,
        once,
        showAuthModal,
        getUserAttributes,
    };
}
