import { BrowserStorage } from 'atc-js';

import { sendClick } from 'reaxl-analytics';

export const threeDaysInMs = 259200000; // 3 * 24 * 60 * 60 * 1000

const compareListingsCache = new BrowserStorage('COMPARE_LISTINGS', {
    persist: true,
});

const compareToggleCache = new BrowserStorage('COMPARE_TOGGLE', {
    persist: false,
});

export function getCompareListingsIds() {
    let currentResults = compareListingsCache.getItem()?.ids || [];
    const comparedDate = compareListingsCache.getItem()?.date;
    const currentDate = (new Date()).getTime();

    if (comparedDate && ((currentDate - comparedDate) > threeDaysInMs)) {
        currentResults = [];
        compareListingsCache.setItem({
            date: '',
            ids: [],
        });
    }
    return currentResults;
}

export function setCompareListingsIds({ date = new Date().getTime(), listingIds = [] }) {
    compareListingsCache.setItem({
        date,
        ids: listingIds,
    });
}

export function getCompareListingsToggle() {
    return compareToggleCache.getItem()?.compareToggle || false;
}

export function setCompareListingsToggle(show) {
    compareToggleCache.setItem({
        compareToggle: show,
    });
}

/**
 *
 * @param addToActiveResults - boolean optional to add current listing to compare store's active results
 * @param event
 * @param fetchCompareListings
 * @param listing - object optional if action involves clicking on a listing
 * @param listingIdsToCompare
 * @param setActiveInteraction
 * @param setWalletInteraction - function optional to update payments state of compare popover
 * @param showCompareModal - boolean optional to not show compare modal
 * @param wasSticky
 */
const handleCompareListings = ({
    addToActiveResults = false,
    isAddToCompare = false,
    event,
    fetchCompareListings,
    listing,
    listingIds,
    setActiveInteraction,
    setWalletInteraction = () => {},
    showCompareModal = true,
    wasSticky,
    showSpotlight,
}) => {
    let listingIdsToCompare = [];
    if (Array.isArray(listingIds)) {
        listingIdsToCompare = listingIdsToCompare.concat(listingIds);
    } else if (listingIds) {
        listingIdsToCompare.push(listingIds);
    }
    if (listing && !listingIdsToCompare.includes(listing?.id)) {
        listingIdsToCompare.push(listing.id);
    }

    setActiveInteraction({
        showCompareModal,
    });
    setWalletInteraction({
        disableComparePopover: true,
    });

    if (addToActiveResults && listing) {
        addToActiveResults(listing?.id);
    }

    // compare when we have multiple listings
    if (listingIdsToCompare.length > 1) {
        const numRecords = 5;
        fetchCompareListings(listingIdsToCompare, numRecords, showSpotlight);
    }
    sendClick('compareListingsClick', event, {
        wasSticky,
        isAddToCompare,
        inventoryId: listing?.id,
    });
};

export default handleCompareListings;
