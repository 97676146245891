import { useEffect, useRef, useState } from 'react';

import { useRouter } from 'next/router';

export const saveScrollPos = (key, selector) => {
    let scrollPos = { x: window.scrollX, y: window.scrollY };

    if (selector) {
        const element = document.querySelector(selector);
        scrollPos = { x: element.scrollLeft, y: element.scrollTop };
    }

    sessionStorage.setItem(`scrollPos:${key}`, JSON.stringify(scrollPos));
};

export const restoreScrollPos = (key, selector) => {
    const json = sessionStorage.getItem(`scrollPos:${key}`);
    const scrollPos = json ? JSON.parse(json) : { x: 0, y: 0 };

    if (selector) {
        const element = document.querySelector(selector);
        setTimeout(() => {
            element.scrollTo({
                left: scrollPos.x,
                top: scrollPos.y,
                behavior: 'smooth',
            });
        }, 100);
    } else {
        window.scrollTo({
            left: scrollPos.x,
            top: scrollPos.y,
            behavior: 'smooth',
        });
    }
};

export const deleteScrollPos = (key) => {
    sessionStorage.removeItem(`scrollPos:${key}`);
};

export function useScrollRestoration({ router = null, enabled = true, selector = null, delay = null } = {}) {
    const nextRouter = useRouter();
    const prevRoutePath = useRef(null);

    if (!router) {
        router = nextRouter;
    }

    const [key, setKey] = useState(null);

    useEffect(() => {
        setKey(router.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!enabled) return () => {};

        const onBeforeUnload = () => {
            deleteScrollPos(key);
            deleteScrollPos(router.pathname);
        };

        const onRouteChangeStart = () => {
            prevRoutePath.current = router.pathname;
            saveScrollPos(key, selector);
        };

        const onRouteChangeComplete = () => {
            setKey(router.pathname);

            if (delay != null) {
                setTimeout(() => {
                    if (prevRoutePath.current !== router.pathname) restoreScrollPos(router.pathname, selector);
                    deleteScrollPos(router.pathname);
                }, delay);
            } else {
                if (prevRoutePath.current !== router.pathname) restoreScrollPos(router.pathname, selector);
                deleteScrollPos(router.pathname);
            }
        };

        window.addEventListener('beforeunload', onBeforeUnload);
        router.events.on('routeChangeStart', onRouteChangeStart);
        router.events.on('routeChangeComplete', onRouteChangeComplete);

        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);
            router.events.off('routeChangeStart', onRouteChangeStart);
            router.events.off('routeChangeComplete', onRouteChangeComplete);
        };
    }, [delay, enabled, key, router.pathname, router.events, selector]);
}
