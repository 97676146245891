import _get from 'lodash/get';
import _merge from 'lodash/merge';

export default function withFeatures({ environment = 'prod' } = {}) {
    return async (ctx) => {

        try {
            if (typeof window === 'undefined' && !ctx.data.configFeatures) {
                const { experience } = ctx.query;
                const { brand } = ctx.data;

                // Server request
                // Load the features from the local files
                const { default: prodFeatures } = await import(`../features/prod/${brand}/${experience ?? brand}.js`);

                let qaFeatures = {};
                if (environment === 'qa') {
                    ({ default: qaFeatures } = await import(`../features/qa/${brand}/${experience ?? brand}.js`));
                }
                // merging features because qa needs some prod features
                ctx.data.configFeatures = _merge(prodFeatures, qaFeatures);
            } else if (typeof window !== 'undefined') {

                if (!ctx.data) {
                    ctx.data = {};
                }
                // Client Request
                // use the value stored in redux as it is loaded once and persisted
                const configFeatures = _get(window, '__NEXT_DATA__.props.featureFlags.config', {});
                ctx.data.configFeatures = {
                    ...configFeatures,
                    lastUpdated: Date.now(),
                };
            }

        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('withFeatures - ', error);
        }
    };
}
